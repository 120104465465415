import { useState } from 'react';
import { Button2 } from '../../Components/Button2';
import {
    DialogClose,
    DialogContent,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from '../../Components/Dialog';
import { Typography } from '../../Components/Typography';

import styles from './styles/meet-with-us-dialog.module.scss';
import { CalendlyWidget } from './CalendlyWidget';
import { VideoPlayer } from '../../Components/VideoPlayer';

interface UplodaAssetsDialogProps {
    children: React.ReactNode;
}

export const MeetWithUsDialog = ({ children }: UplodaAssetsDialogProps) => {
    const [open, setOpen] = useState(false);
    const [next, setNext] = useState(false);

    const handleSetOpen = (open: boolean) => {
        if (!open) {
            setNext(false);
        }
        setOpen(open);
    };

    const handleToggleNext = () => {
        setNext(!next);
    };

    return (
        <DialogRoot open={open} onOpenChange={handleSetOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent aria-describedby="desc1 desc2">
                <div className={styles.dialogContent}>
                    {next ? (
                        <ScheduleMeeting handleToggleNext={handleToggleNext} />
                    ) : (
                        <MeetWithUs handleToggleNext={handleToggleNext} />
                    )}
                </div>
            </DialogContent>
        </DialogRoot>
    );
};

interface DialogContentProps {
    handleToggleNext: () => void;
}

const MeetWithUs = ({ handleToggleNext }: DialogContentProps) => {
    return (
        <>
            <div className={styles.left}>
                <div>
                    <DialogTitle asChild>
                        <Typography variant="heading1" component="h2">
                            Meet With Us
                        </Typography>
                    </DialogTitle>
                    <Typography id="desc1">
                        Check out the video for what to expect in your meeting! a meeting to provide
                        us any garment images, model garment images, and tech packs you’d like to
                        see in your internal testing app.
                    </Typography>
                </div>
                <Button2 color="secondary" variant="outlined" onClick={handleToggleNext}>
                    Next
                </Button2>
            </div>
            <div className={styles.right}>
                <VideoPlayer src="https://superb-actor-37d8b69f79.media.strapiapp.com/What_to_Expect_Video_Optimized_245fdf1981.mp4" />
            </div>
        </>
    );
};

const ScheduleMeeting = ({ handleToggleNext }: DialogContentProps) => {
    return (
        <>
            <div className={styles.left}>
                <div>
                    <DialogTitle asChild>
                        <Typography variant="heading1" component="h2">
                            Schedule
                        </Typography>
                    </DialogTitle>
                    <Typography id="desc1">
                        Schedule a meeting to provide us any garment images, model garment images,
                        and tech packs you’d like to see in your internal testing app.
                    </Typography>
                </div>
                <div className={styles.dialogButtons}>
                    <Button2 variant="text" onClick={handleToggleNext}>
                        Back
                    </Button2>
                    <DialogClose asChild>
                        <Button2 color="secondary" variant="outlined">
                            Close
                        </Button2>
                    </DialogClose>
                </div>
            </div>
            <div className={styles.right}>
                <CalendlyWidget dataUrl="https://calendly.com/erikguntner-me" />
            </div>
        </>
    );
};
