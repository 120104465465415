import { ArrowLeft, Cardholder, Invoice } from '@phosphor-icons/react';
import moment, { unix } from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetInvoices } from '../../Features/payments/queries';
import { useStores } from '../../Hooks/use-stores';
import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';
import classNames from 'classnames';
import { Link } from '../../Components/Link';
import CONSTANTS from '../../Constants';
import { BillingPortalButton } from '../../Features/payments/BillingPortalButton';
import styles from './index.module.scss';
import { Spinner } from '../../Components/Spinner';

export const Billing = () => {
    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div>
                    <Link
                        pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}
                        className={styles.back}
                    >
                        <ArrowLeft size={32} fill="#000" />
                        Back
                    </Link>
                </div>
                <div className={styles.header}>
                    <Typography variant="heading1">Billing & Payments</Typography>
                </div>
                <div className={styles.billingCards}>
                    <div className={classNames(styles.card)}>
                        <Typography component="h4">Your Upcoming Payment</Typography>
                        <div className={styles.upcomingDetails}>
                            <div className={styles.price}>$ 12,345</div>
                            <div className={styles.upcomingDetailsFooter}>
                                <div className={classNames([styles.tab, styles.unpaid])}>Due</div>
                                <Typography>Due : November 30, 2024</Typography>
                            </div>
                        </div>
                        <Button2 className={styles.whiteButton}>View Invoice</Button2>
                    </div>
                    <div className={classNames(styles.card, styles.paymentMethod)}>
                        <div className={styles.cardHeader}>
                            <CardHolderIcon />
                            <Typography component="h4">
                                Need To Update Your Payment Method?
                            </Typography>
                            <Typography>
                                Keep your payments running smoothly by updating your payment method
                                here.
                            </Typography>
                        </div>
                        <BillingPortalButton>Manage Payment Method</BillingPortalButton>
                    </div>
                </div>

                <div className={styles.table}>
                    <div className={classNames([styles.invoice, styles.billingHeader])}>
                        <div>Date</div>
                        <div>Description</div>
                        <div>Amount</div>
                        <div>Status</div>
                        <div></div>
                    </div>
                    <InvoiceList />
                </div>
            </div>
        </main>
    );
};

const InvoiceList = () => {
    const { userStore } = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    const handlePayInvoice = (invoiceId: string, paymentIntentId: string) => {
        navigate(`/invoice`, {
            state: {
                from: location,
                invoiceId,
                paymentIntentId,
            },
        });
    };

    const invoicesQuery = useGetInvoices({ userId: userStore.userInfo.id });

    if (invoicesQuery.isLoading) {
        return (
            <div className={styles.loadingContianer}>
                <Spinner />
            </div>
        );
    }

    if (!invoicesQuery.data) {
        return (
            <div className={styles.noInvoices}>
                <InvoiceIcon />
                <Typography variant="heading1" component="h4" style={{ fontWeight: 400 }}>
                    No Record of Invoices
                </Typography>
                <Typography variant="body1" component="p">
                    No payments have been made yet, so there are currently no invoice records to
                    display.
                </Typography>
            </div>
        );
    }

    return (
        <ul>
            {invoicesQuery?.data?.length &&
                // @ts-ignore
                invoicesQuery.data.map((invoice) => {
                    const isPaid = invoice.status === 'paid';
                    const date = moment(unix(invoice.effective_at)).format('MM/DD/YYYY');

                    return (
                        <li className={styles.invoice} key={invoice.id}>
                            <div>
                                <Typography>
                                    <span>{date}</span>
                                </Typography>
                            </div>
                            <div>
                                <Typography>{invoice.lines.data[0]?.description ?? ''}</Typography>
                            </div>
                            <div>
                                <p>${invoice.amount_due / 100}</p>
                            </div>
                            <div>
                                <div
                                    className={classNames([
                                        styles.tab,
                                        isPaid ? styles.paid : styles.unpaid,
                                    ])}
                                >
                                    {invoice.status}
                                </div>
                            </div>
                            <div>
                                {invoice.hosted_invoice_url && invoice.status !== 'void' ? (
                                    <Button2
                                        className={styles.button}
                                        variant="outlined"
                                        color="secondary"
                                        asChild
                                    >
                                        <a
                                            href={invoice.hosted_invoice_url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View Invoice
                                        </a>
                                    </Button2>
                                ) : null}
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
};

const CardHolderIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            style={{
                marginTop: '-20px',
                marginLeft: '-10px',
            }}
        >
            <g clip-path="url(#clip0_7_6145)">
                <path
                    opacity="0.2"
                    d="M40 47.5C42.6522 47.5 45.1957 46.4464 47.0711 44.5711C48.9464 42.6957 50 40.1522 50 37.5H70V22.5C70 21.1739 69.4732 19.9021 68.5355 18.9645C67.5979 18.0268 66.3261 17.5 65 17.5H15C13.6739 17.5 12.4021 18.0268 11.4645 18.9645C10.5268 19.9021 10 21.1739 10 22.5V37.5H30C30 40.1522 31.0536 42.6957 32.9289 44.5711C34.8043 46.4464 37.3478 47.5 40 47.5Z"
                    fill="black"
                />
                <path
                    d="M65 17.5H15C12.2386 17.5 10 19.7386 10 22.5V57.5C10 60.2614 12.2386 62.5 15 62.5H65C67.7614 62.5 70 60.2614 70 57.5V22.5C70 19.7386 67.7614 17.5 65 17.5Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10 37.5H30C30 40.1522 31.0536 42.6957 32.9289 44.5711C34.8043 46.4464 37.3478 47.5 40 47.5C42.6522 47.5 45.1957 46.4464 47.0711 44.5711C48.9464 42.6957 50 40.1522 50 37.5H70"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10 27.5H70"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_6145">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const InvoiceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
        >
            <g clip-path="url(#clip0_7_6159)">
                <path
                    opacity="0.2"
                    d="M52.5 62.5V32.5H70V60C70 60.663 69.7366 61.2989 69.2678 61.7678C68.7989 62.2366 68.163 62.5 67.5 62.5H52.5Z"
                    fill="black"
                />
                <path
                    d="M20 17.5H12.5C11.1739 17.5 9.90215 18.0268 8.96447 18.9645C8.02678 19.9021 7.5 21.1739 7.5 22.5C7.5 23.8261 8.02678 25.0979 8.96447 26.0355C9.90215 26.9732 11.1739 27.5 12.5 27.5H17.5C18.8261 27.5 20.0979 28.0268 21.0355 28.9645C21.9732 29.9021 22.5 31.1739 22.5 32.5C22.5 33.8261 21.9732 35.0979 21.0355 36.0355C20.0979 36.9732 18.8261 37.5 17.5 37.5H8.75"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15 15V17.5"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15 37.5V40"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M30 17.5H70V60C70 60.663 69.7366 61.2989 69.2678 61.7678C68.7989 62.2366 68.163 62.5 67.5 62.5H12.5C11.837 62.5 11.2011 62.2366 10.7322 61.7678C10.2634 61.2989 10 60.663 10 60V47.5"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M32.5 32.5H70"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M25 47.5H70"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M52.5 32.5V62.5"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_6159">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
