import CONSTANTS from '../Constants';
import { OptionSEO } from '../Layout/default-layout';
import ContactUs from '../Screens/ContactUs';
import ExperiencePage from '../Screens/Experience';
import NotFound404 from '../Screens/NotFound404';
import HomePage from '../Screens/Home';
import TermsPolicyPage from '../Screens/LegalText/TermsPolicy';
import LeadersPage from '../Screens/AboutUs';
import PrivacyPolicyPage from '../Screens/LegalText/PrivacyPolicy';
import { Login } from '../Screens/Login';
import InstructionsSent from '../Screens/PasswordResetFlow/InstructionsSent';
import SetNewPassword from '../Screens/PasswordResetFlow/SetNewPassword';
import PasswordSuccess from '../Screens/PasswordResetFlow/PasswordSuccess';
import CreateAccountFlow from '../Screens/CreateAccountFlow';
import SupportPage from '../Screens/Support';
import CreateAccountLandingPage from '../Screens/CreateAccountFlow/LandingPage';
import PartnerRessourcesPage from '../Screens/PartnerRessources';
import EmailConfirmed from '../Screens/CreateAccountFlow/EmailConfirmed';
import LifeAtSpreeAIPage from '../Screens/LifeAtSpreeAI';
import DashboardPage from '../Screens/Dashboard';
import BiometricConsent from '../Screens/LegalText/BiometricConsent';
import { CareersPage } from '../Screens/Careers';
import DpaPage from '../Screens/LegalText/Dpa';
import FAQPage from '../Screens/FAQ';
import BlogPage from '../Screens/Blog';
import BlogArticle from '../Screens/Blog/id';
import ImpactsAndInclusions from '../Screens/ImpactsAndInclusions';
import { JobsPage } from '../Screens/Jobs';
import { Payments } from '../Screens/Payments';
import { Billing } from '../Screens/Billing';
import { Checkout } from '../Screens/Checkout';
import { PartnerPortal } from '../Screens/PartnerPortal';
import { EngineeringPage } from '../Screens/Engineering';
import { AccountDeleted } from '../Screens/AccountDeleted';
import { CheckoutEarlyAccess } from '../Screens/CheckoutEarlyAcces';
import { PaymentSuccess } from '../Screens/PaymentSuccess';
import { Welcome } from '../Screens/Welcome';
import { IS_DEV } from '../Utils/object';
import { ManageAssets } from '../Screens/ManageAssets';
import { Pricing } from '../Screens/Pricing';
import { InternalTesting } from '../Screens/InteralTesting';
import { Phase1 } from '../Screens/Phase1';
import { PendingItems } from '../Screens/PendingItems';

export interface ROUTE {
    path: string;
    seo?: {
        fr: OptionSEO;
        en: OptionSEO;
        it: OptionSEO;
        es: OptionSEO;
    };
    children?: () => JSX.Element;
    restricted?: {
        needLogin: boolean;
        needApproval: boolean;
    };
    redirect?: string;
}

const PartnerPortalRouter: ROUTE[] = [
    {
        path: CONSTANTS.SCREEN.BILLING,
        children: Billing,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.CHECKOUT,
        children: Checkout,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.CHECKOUT_EARLY_ACCESS,
        children: CheckoutEarlyAccess,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.INTERNAL_TESTING,
        children: InternalTesting,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.MANAGE_ASSESTS,
        children: ManageAssets,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PARTNER_PORTAL,
        children: PartnerPortal,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PAYMENTS,
        children: Payments,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PAYMENTS_SUCCESS,
        children: PaymentSuccess,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PENDING_ITEMS,
        children: PendingItems,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PHASE_ONE,
        children: Phase1,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PRICING,
        children: Pricing,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.WELCOME,
        children: Welcome,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
];

export const ROUTER_CONFIG: ROUTE[] = [
    {
        path: CONSTANTS.SCREEN.HOME,
        seo: {
            fr: {
                title: 'SpreeAI',
                description:
                    "SpreeAI est l'endroit où votre expérience d'achat est associée à l'intelligence artificielle.",
                type: '',
                name: 'Home',
            },
            en: {
                title: 'SpreeAI',
                description: 'SpreeAI is where your shopping experience is combined with AI.',
                type: '',
                name: 'Home',
            },
            it: {
                title: 'SpreeAI',
                description: "SpreeAI è dove la tua esperienza di shopping si combina con l'IA.",
                type: '',
                name: 'Home',
            },
            es: {
                title: 'SpreeAI',
                description: 'SpreeAI es donde tu experiencia de compra se combina con la IA.',
                type: '',
                name: 'Home',
            },
        },
        children: HomePage,
    },
    {
        path: CONSTANTS.SCREEN.ABOUT_US,
        seo: {
            fr: {
                title: 'About Us',
                description:
                    "Découvrez la culture vibrante d'innovation et de collaboration de SpreeAI sur notre page 'À Propos'. Explorez comment notre équipe diversifiée de professionnels talentueux travaille ensemble pour repousser les limites de l'intelligence artificielle et créer des solutions révolutionnaires",
                type: '',
                name: 'About Us',
            },
            en: {
                title: 'About Us',
                description:
                    "Discover SpreeAI's vibrant culture of innovation and collaboration on our 'About Us' page. Explore how our diverse team of talented professionals works together to push the boundaries of artificial intelligence and create groundbreaking solutions",
                type: '',
                name: 'About Us',
            },
            it: {
                title: 'Chi siamo',
                description:
                    "Scopri la vibrante cultura di innovazione e collaborazione di SpreeAI nella nostra pagina 'Chi Siamo'. Scopri come il nostro team diversificato di professionisti talentuosi lavora insieme per spingere i limiti dell'intelligenza artificiale e creare soluzioni rivoluzionarie",
                type: '',
                name: 'Chi siamo',
            },
            es: {
                title: 'Sobre nosotros',
                description:
                    "Descubra la vibrante cultura de innovación y colaboración de SpreeAI en nuestra página 'Sobre nosotros'. Explore cómo nuestro diverso equipo de profesionales talentosos trabaja juntos para empujar los límites de la inteligencia artificial y crear soluciones innovadoras",
                type: '',
                name: 'Sobre nosotros',
            },
        },
        children: ExperiencePage,
    },
    {
        path: CONSTANTS.SCREEN.SUPPORT,
        seo: {
            fr: {
                title: 'Support',
                description: 'Page de Support',
                type: '',
                name: 'Support',
            },
            en: {
                title: 'Support',
                description: 'Support page',
                type: '',
                name: 'Support',
            },
            it: {
                title: 'Supporto',
                description: 'Pagina di supporto',
                type: '',
                name: 'Supporto',
            },
            es: {
                title: 'Apoyo',
                description: 'Página de soporte',
                type: '',
                name: 'Apoyo',
            },
        },
        children: SupportPage,
        restricted: {
            needLogin: false,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.TERMS_OF_SERVICE,
        seo: {
            fr: {
                title: 'Terms of Service',
                description: "Page des Conditions d'Utilisation",
                type: '',
                name: 'Terms of Service',
            },
            en: {
                title: 'Terms of Service',
                description: 'Terms of Service Page',
                type: '',
                name: 'Terms of Service',
            },
            it: {
                title: 'Termini di Servizio',
                description: 'Pagina dei Termini di Servizio',
                type: '',
                name: 'Termini di Servizio',
            },
            es: {
                title: 'Términos de Servicio',
                description: 'Página de Términos de Servicio',
                type: '',
                name: 'Términos de Servicio',
            },
        },
        children: TermsPolicyPage,
    },
    {
        path: CONSTANTS.SCREEN.MEET_OUR_TEAM,
        seo: {
            fr: {
                title: 'Rencontrez notre équipe',
                description:
                    "Découvrez la culture vibrante d'innovation et de collaboration de SpreeAI sur notre page 'À Propos'. Explorez comment notre équipe diversifiée de professionnels talentueux travaille ensemble pour repousser les limites de l'intelligence artificielle et créer des solutions révolutionnaires",
                type: '',
                name: 'Rencontrez notre équipe',
            },
            en: {
                title: 'Meet Our Team',
                description:
                    "Discover SpreeAI's vibrant culture of innovation and collaboration on our 'About Us' page. Explore how our diverse team of talented professionals works together to push the boundaries of artificial intelligence and create groundbreaking solutions",
                type: '',
                name: 'Meet Our Team',
            },
            it: {
                title: 'Incontra il nostro team',
                description:
                    "Scopri la vibrante cultura di innovazione e collaborazione di SpreeAI nella nostra pagina 'Chi Siamo'. Scopri come il nostro team diversificato di professionisti talentuosi lavora insieme per spingere i limiti dell'intelligenza artificiale e creare soluzioni rivoluzionarie",
                type: '',
                name: 'Incontra il nostro team',
            },
            es: {
                title: 'Conozca a nuestro equipo',
                description:
                    "Descubra la vibrante cultura de innovación y colaboración de SpreeAI en nuestra página 'Sobre nosotros'. Explore cómo nuestro diverso equipo de profesionales talentosos trabaja juntos para empujar los límites de la inteligencia artificial y crear soluciones innovadoras",
                type: '',
                name: 'Conozca a nuestro equipo',
            },
        },
        children: LeadersPage,
    },
    {
        path: CONSTANTS.SCREEN.CONTACT_US,
        seo: {
            fr: {
                title: 'Contact Us',
                description: 'Page de Contact',
                type: '',
                name: 'Contact Us',
            },
            en: {
                title: 'Contact Us',
                description: 'Contact Us page',
                type: '',
                name: 'Contact Us',
            },
            it: {
                title: 'Contattaci',
                description: 'Pagina di Contatto',
                type: '',
                name: 'Contattaci',
            },
            es: {
                title: 'Contáctenos',
                description: 'Página de contacto',
                type: '',
                name: 'Contáctenos',
            },
        },
        children: ContactUs,
    },
    {
        path: CONSTANTS.SCREEN.PRIVACY_NOTICE,
        seo: {
            fr: {
                title: 'Avis de Confidentialité',
                description: 'Page de Politique de Confidentialité',
                type: '',
                name: 'Avis de Confidentialité',
            },
            en: {
                title: 'Privacy Notice',
                description: 'Privacy Notice page',
                type: '',
                name: 'Privacy Notice',
            },
            it: {
                title: 'Avviso sulla Privacy',
                description: 'Pagina della Politica sulla Privacy',
                type: '',
                name: 'Avviso sulla Privacy',
            },
            es: {
                title: 'Aviso de Privacidad',
                description: 'Página de Aviso de Privacidad',
                type: '',
                name: 'Aviso de Privacidad',
            },
        },
        children: PrivacyPolicyPage,
    },
    {
        path: CONSTANTS.SCREEN.PRIVACY_POLICY,
        redirect: CONSTANTS.SCREEN.PRIVACY_NOTICE,
    },
    {
        path: CONSTANTS.SCREEN.BIOMETRIC_CONSENT,
        seo: {
            fr: {
                title: 'Biometric Consent',
                description: 'Consentement Biométrique',
                type: '',
                name: 'Biometric Consent',
            },
            en: {
                title: 'Biometric Consent',
                description: 'Biometric Consent',
                type: '',
                name: 'Biometric Consent',
            },
            it: {
                title: 'Biometric Consent',
                description: 'Consenso Biometrico',
                type: '',
                name: 'Biometric Consent',
            },
            es: {
                title: 'Consentimiento Biométrico',
                description: 'Consentimiento Biométrico',
                type: '',
                name: 'Consentimiento Biométrico',
            },
        },
        children: BiometricConsent,
    },
    {
        path: CONSTANTS.SCREEN.LOGIN,
        seo: {
            fr: {
                title: 'Login',
                description: 'Connexion',
                type: '',
                name: 'Login',
            },
            en: {
                title: 'Login',
                description: 'Login',
                type: '',
                name: 'Login',
            },
            it: {
                title: 'Login',
                description: 'Accesso',
                type: '',
                name: 'Login',
            },
            es: {
                title: 'Inicio de Sesión',
                description: 'Inicio de Sesión',
                type: '',
                name: 'Inicio de Sesión',
            },
        },
        children: Login,
    },
    {
        path: CONSTANTS.SCREEN.PASSWORD_RESET_SENT,
        seo: {
            fr: {
                title: 'Reset Instructions Sent',
                description: 'Instructions de Réinitialisation Envoyées',
                type: '',
                name: 'Reset Instructions Sent',
            },
            en: {
                title: 'Reset Instructions Sent',
                description: 'Reset Instructions Sent',
                type: '',
                name: 'Reset Instructions Sent',
            },
            it: {
                title: 'Reset Instructions Sent',
                description: 'Istruzioni di Reimpostazione Inviata',
                type: '',
                name: 'Reset Instructions Sent',
            },
            es: {
                title: 'Instrucciones de Restablecimiento Enviadas',
                description: 'Instrucciones de Restablecimiento Enviadas',
                type: '',
                name: 'Instrucciones de Restablecimiento Enviadas',
            },
        },
        children: InstructionsSent,
    },
    {
        path: CONSTANTS.SCREEN.SET_NEW_PASSWORD,
        seo: {
            fr: {
                title: 'Set New Password',
                description: 'Définir un Nouveau Mot de Passe',
                type: '',
                name: 'Set New Password',
            },
            en: {
                title: 'Set New Password',
                description: 'Set New Password',
                type: '',
                name: 'Set New Password',
            },
            it: {
                title: 'Set New Password',
                description: 'Imposta Nuova Password',
                type: '',
                name: 'Set New Password',
            },
            es: {
                title: 'Establecer Nueva Contraseña',
                description: 'Establecer Nueva Contraseña',
                type: '',
                name: 'Establecer Nueva Contraseña',
            },
        },
        children: SetNewPassword,
        restricted: {
            needLogin: false,
            needApproval: false,
        },
    },
    {
        path: CONSTANTS.SCREEN.NEW_PASSWORD_SUCCESS,
        seo: {
            fr: {
                title: 'New Password Success',
                description: 'Nouveau Mot de Passe Réussi',
                type: '',
                name: 'New Password Success',
            },
            en: {
                title: 'New Password Success',
                description: 'New Password Success',
                type: '',
                name: 'New Password Success',
            },
            it: {
                title: 'New Password Success',
                description: 'Nuova Password Riuscita',
                type: '',
                name: 'New Password Success',
            },
            es: {
                title: 'Nueva Contraseña Exitosa',
                description: 'Nueva Contraseña Exitosa',
                type: '',
                name: 'Nueva Contraseña Exitosa',
            },
        },
        children: PasswordSuccess,
    },
    {
        path: CONSTANTS.SCREEN.CREATE_ACCOUNT,
        seo: {
            fr: {
                title: 'Create Account',
                description: 'Créer un Compte',
                type: '',
                name: 'Create Account',
            },
            en: {
                title: 'Create Account',
                description: 'Create Account',
                type: '',
                name: 'Create Account',
            },
            it: {
                title: 'Create Account',
                description: 'Crea un Account',
                type: '',
                name: 'Create Account',
            },
            es: {
                title: 'Crear Cuenta',
                description: 'Crear Cuenta',
                type: '',
                name: 'Crear Cuenta',
            },
        },
        children: CreateAccountLandingPage,
    },
    {
        path: CONSTANTS.SCREEN.PARTNER_ASKS,
        seo: {
            fr: {
                title: 'Partners Asks',
                description: 'Asks Partenaires',
                type: '',
                name: 'Partners Asks',
            },
            en: {
                title: 'Partners Asks',
                description: 'Partners Asks',
                type: '',
                name: 'Partners Asks',
            },
            it: {
                title: 'Partner Chiede',
                description: 'Partner Chiede',
                type: '',
                name: 'Partner Chiede',
            },
            es: {
                title: 'Socios Preguntan',
                description: 'Socios Preguntan',
                type: '',
                name: 'Socios Preguntan',
            },
        },
        children: PartnerRessourcesPage,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.CREATE_ACCOUNT_FLOW,
        seo: {
            fr: {
                title: 'Create Account',
                description: 'Créer un Compte',
                type: '',
                name: 'Create Account',
            },
            en: {
                title: 'Create Account',
                description: 'Create Account',
                type: '',
                name: 'Create Account',
            },
            it: {
                title: 'Create Account',
                description: 'Crea un Account',
                type: '',
                name: 'Create Account',
            },
            es: {
                title: 'Crear Cuenta',
                description: 'Crear Cuenta',
                type: '',
                name: 'Crear Cuenta',
            },
        },
        children: CreateAccountFlow,
    },
    {
        path: CONSTANTS.SCREEN.EMAIL_CONFIRMED,
        seo: {
            fr: {
                title: 'Email Confirmed',
                description: 'E-mail Confirmé',
                type: '',
                name: 'Email Confirmed',
            },
            en: {
                title: 'Email Confirmed',
                description: 'Email Confirmed',
                type: '',
                name: 'Email Confirmed',
            },
            it: {
                title: 'Email Confermata',
                description: 'Email Confermata',
                type: '',
                name: 'Email Confermata',
            },
            es: {
                title: 'Correo Electrónico Confirmado',
                description: 'Correo Electrónico Confirmado',
                type: '',
                name: 'Correo Electrónico Confirmado',
            },
        },
        children: EmailConfirmed,
    },
    {
        path: CONSTANTS.SCREEN.ACCOUNT_DELETED,
        seo: {
            fr: {
                title: 'Compte Supprimé',
                description: 'Compte Supprimé',
                type: '',
                name: 'Compte Supprimé',
            },
            en: {
                title: 'Account Deleted',
                description: 'Account Deleted',
                type: '',
                name: 'Account Deleted',
            },
            it: {
                title: 'Account Cancellato',
                description: 'Account Cancellato',
                type: '',
                name: 'Account Cancellato',
            },
            es: {
                title: 'Cuenta Eliminada',
                description: 'Cuenta eliminada',
                type: '',
                name: 'Cuenta Eliminada',
            },
        },
        children: AccountDeleted,
    },
    {
        path: CONSTANTS.SCREEN.DPA,
        seo: {
            fr: {
                title: 'DPA',
                description: 'DPA (Accord de Traitement des Données)',
                type: '',
                name: 'DPA',
            },
            en: {
                title: 'DPA',
                description: 'DPA',
                type: '',
                name: 'DPA',
            },
            it: {
                title: 'DPA',
                description: 'DPA',
                type: '',
                name: 'DPA',
            },
            es: {
                title: 'DPA',
                description: 'DPA',
                type: '',
                name: 'DPA',
            },
        },
        children: DpaPage,
    },
    {
        path: CONSTANTS.SCREEN.LIFE_AT_SPREEAI,
        seo: {
            fr: {
                title: 'Vie à SpreeAI',
                description:
                    'Découvrez le travail à distance comme jamais auparavant chez SpreeAI. Découvrez notre culture innovante et redéfinissez la productivité. Bienvenue dans la vie chez SpreeAI, là où commence le futur du travail à distance',
                type: '',
                name: 'Vie à SpreeAI',
            },
            en: {
                title: 'Life At SpreeAI',
                description:
                    'Experience remote work like never before at SpreeAI. Discover our innovative culture and redefine productivity. Welcome to Life At SpreeAI, where the future of remote work begins',
                type: '',
                name: 'Life At SpreeAI',
            },
            it: {
                title: 'Vita a SpreeAI',
                description:
                    "Vivi il lavoro remoto come mai prima d'ora da SpreeAI. Scopri la nostra cultura innovativa e ridefinisci la produttività. Benvenuti in Vita a SpreeAI, dove inizia il futuro del lavoro remoto",
                type: '',
                name: 'Vita a SpreeAI',
            },
            es: {
                title: 'La vida en SpreeAI',
                description:
                    'Experimente el trabajo remoto como nunca antes en SpreeAI. Descubra nuestra cultura innovadora y redefina la productividad. Bienvenido a La vida en SpreeAI, donde comienza el futuro del trabajo remoto',
                type: '',
                name: 'La vida en SpreeAI',
            },
        },
        children: LifeAtSpreeAIPage,
    },
    {
        path: CONSTANTS.SCREEN.CAREERS,
        seo: {
            fr: {
                title: 'Carrières',
                description: 'Travailler Chez SpreeAI',
                type: '',
                name: 'Carrières',
            },
            en: {
                title: 'Careers',
                description: 'Join SpreeAI',
                type: '',
                name: 'Careers',
            },
            it: {
                title: 'Carriere',
                description: 'Unisciti a SpreeAI',
                type: '',
                name: 'Carriere',
            },
            es: {
                title: 'Carreras',
                description: 'Únete a SpreeAI',
                type: '',
                name: 'Carreras',
            },
        },
        children: CareersPage,
    },
    {
        path: CONSTANTS.SCREEN.JOBS,
        seo: {
            fr: {
                title: 'Emplois',
                description: "Offres d'emploi",
                type: '',
                name: 'Emplois',
            },
            en: {
                title: 'Jobs',
                description: 'Job listings',
                type: '',
                name: 'Jobs',
            },
            it: {
                title: 'Lavori',
                description: 'Lavori',
                type: '',
                name: 'Lavori',
            },
            es: {
                title: 'Trabajos',
                description: 'Trabajos',
                type: '',
                name: 'Trabajos',
            },
        },
        children: JobsPage,
    },
    {
        path: CONSTANTS.SCREEN.DASHBOARD,
        children: DashboardPage,
        restricted: {
            needLogin: true,
            needApproval: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.FAQ,
        seo: {
            fr: {
                title: 'FAQ',
                description: 'Page de FAQ',
                type: '',
                name: 'FAQ',
            },
            en: {
                title: 'FAQ',
                description: 'FAQ page',
                type: '',
                name: 'FAQ',
            },
            it: {
                title: 'FAQ',
                description: 'Pagina delle FAQ',
                type: '',
                name: 'FAQ',
            },
            es: {
                title: 'FAQ',
                description: 'Página de preguntas frecuentes',
                type: '',
                name: 'FAQ',
            },
        },
        children: FAQPage,
        restricted: {
            needLogin: false,
            needApproval: false,
        },
    },
    {
        path: CONSTANTS.SCREEN.BLOG,
        seo: {
            fr: {
                title: 'Blog',
                description: 'Page de Blog',
                type: '',
                name: 'Blog',
            },
            en: {
                title: 'Blog',
                description: 'Blog page',
                type: '',
                name: 'Blog',
            },
            it: {
                title: 'Blog',
                description: 'Pagina del Blog',
                type: '',
                name: 'Blog',
            },
            es: {
                title: 'Blog',
                description: 'Página de Blog',
                type: '',
                name: 'Blog',
            },
        },
        children: BlogPage,
        restricted: {
            needLogin: false,
            needApproval: false,
        },
    },
    {
        path: CONSTANTS.SCREEN.BLOG_ID,
        seo: {
            fr: {
                title: 'Blog',
                description: 'Page de Blog',
                type: '',
                name: 'Blog',
            },
            en: {
                title: 'Blog',
                description: 'Blog page',
                type: '',
                name: 'Blog',
            },
            it: {
                title: 'Blog',
                description: 'Pagina del Blog',
                type: '',
                name: 'Blog',
            },
            es: {
                title: 'Blog',
                description: 'Página de Blog',
                type: '',
                name: 'Blog',
            },
        },
        children: BlogArticle,
        restricted: {
            needLogin: false,
            needApproval: false,
        },
    },
    {
        path: CONSTANTS.SCREEN.IMPACTS_AND_INCLUSIONS,
        children: ImpactsAndInclusions,
        seo: {
            en: {
                title: 'Impact and Inclusion',
                description: 'Impact and Inclusion',
                type: '',
                name: 'Impact and Inclusion',
            },
            fr: {
                title: 'Impact and Inclusion',
                description: 'Impact and Inclusion',
                type: '',
                name: 'Impact and Inclusion',
            },
            it: {
                title: 'Impatti and Inclusioni',
                description: 'Impatti and Inclusioni',
                type: '',
                name: 'Impatti and Inclusioni',
            },
            es: {
                title: 'Impacto and Inclusione',
                description: 'Impacto and Inclusione',
                type: '',
                name: 'Impacto and Inclusione',
            },
        },
    },
    {
        path: '*',
        seo: {
            fr: {
                title: 'Page Not Found',
                description: 'Page Non Trouvée',
                type: '',
                name: 'Page Not Found',
            },
            en: {
                title: 'Page Not Found',
                description: 'Page Not Found',
                type: '',
                name: 'Page Not Found',
            },
            it: {
                title: 'Pagina Non Trovata',
                description: 'Pagina Non Trovata',
                type: '',
                name: 'Pagina Non Trovata',
            },
            es: {
                title: 'Página no Encontrada',
                description: 'Página no Encontrada',
                type: '',
                name: 'Página no Encontrada',
            },
        },
        children: NotFound404,
    },
    ...(IS_DEV ? PartnerPortalRouter : []),
    ...(IS_DEV
        ? [
              {
                  children: EngineeringPage,
                  path: CONSTANTS.SCREEN.ENGINEERING,
                  seo: {
                      fr: {
                          title: 'Engenierie',
                          description: 'Engenierie',
                          type: '',
                          name: 'Engenierie',
                      },
                      en: {
                          title: 'Engineering',
                          description: 'Engineering',
                          type: '',
                          name: 'Engineering',
                      },
                      it: {
                          title: 'Ingegneria',
                          description: 'Ingegneria',
                          type: '',
                          name: 'Ingegneria',
                      },
                      es: {
                          title: 'Ingenieria',
                          description: 'Ingenieria',
                          type: '',
                          name: 'Ingenieria',
                      },
                  },
              },
          ]
        : []),
];
