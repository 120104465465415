import { useEffect } from 'react';
import { Tour } from 'shepherd.js';
import { offset } from '@floating-ui/dom';

import { useShepherdTour } from './useShepherdTour';

export const addSteps = (tour: Tour) => {
    tour.addStep({
        id: 'internal-testing',
        title: `Internal Testing`,
        text: `Bring your brand to life by personalizing and testing your garments with Internal Testing! <div class="shepherd-count">1/4</div>`,
        attachTo: {
            element: '#internal-testing',
            on: 'auto',
        },
        scrollTo: {
            behavior: 'smooth',
            block: 'nearest',
        },
        classes: 'shepard-element',
        modalOverlayOpeningRadius: 66,
        buttons: [
            {
                text: 'Next',
                action: tour.next,
            },
        ],
    });

    tour.addStep({
        id: 'assets',
        title: 'Manage Your Garments',
        text: 'Upload your assets, including garment + model images and tech packs.<div class="shepherd-count">2/4</div>',
        attachTo: {
            element: '#assets',
            on: 'auto',
        },
        classes: 'shepard-element',
        modalOverlayOpeningRadius: 36,
        scrollTo: {
            behavior: 'smooth',
            block: 'center',
        },
        buttons: [
            {
                text: 'Next',
                action: tour.next,
            },
        ],
    });

    tour.addStep({
        id: 'billing',
        title: 'Billing & Payment',
        text: 'Manage your payment and billing through our integrated service.<div class="shepherd-count">3/4</div>',
        attachTo: {
            element: '#billing',
            on: 'auto',
        },
        scrollTo: {
            behavior: 'smooth',
            block: 'center',
        },
        classes: 'shepard-element',
        modalOverlayOpeningRadius: 36,
        buttons: [
            {
                text: 'Next',
                action: tour.next,
            },
        ],
    });

    tour.addStep({
        id: 'support',
        title: 'Contact Support',
        text: 'For any questions or concerns, contact our team.<div class="shepherd-count">4/4</div>',
        attachTo: {
            element: '#support',
            on: 'auto',
        },
        scrollTo: {
            behavior: 'smooth',
            block: 'center',
        },
        classes: 'shepard-element',
        modalOverlayOpeningRadius: 36,
        buttons: [
            {
                text: 'Get Started!',
                action: tour.complete,
            },
        ],
    });
};

interface UseTour {
    handleOnComplete: () => void;
}

export const useTour = ({ handleOnComplete }: UseTour) => {
    const tour = useShepherdTour({
        tourOptions: {
            useModalOverlay: true,
            defaultStepOptions: {
                modalOverlayOpeningPadding: 16,
                cancelIcon: {
                    enabled: false,
                },
                floatingUIOptions: {
                    middleware: [offset({ mainAxis: 32 })],
                },
            },
        },
        renderSteps: addSteps,
    });

    useEffect(() => {
        if (tour.isActive()) {
            return;
        }
        tour.start();
        tour.on('complete', handleOnComplete);

        return () => {
            tour.cancel();
        };
    }, []);

    return tour;
};
