import { action, makeAutoObservable, observable } from 'mobx';
import CONSTANTS from '../Constants';
import AccountService from '../Services/Account';
import TokenStore from './TokenStore';

export interface UserInfo {
    id: string;
    partner_id: string;
    created: string;
    created_by: string;
    updated: string;
    updated_by: string;
    first_name: string;
    last_name: string;
    email: string;
    company: string;
    company_role: string;
    approved: string;
    confirmed: string;
    approved_by: string;
    company_url?: string;
}

const defaultUserInfo: UserInfo = {
    id: '',
    created: '',
    created_by: '',
    updated: '',
    updated_by: '',
    first_name: '',
    partner_id: '',
    last_name: '',
    email: '',
    company: '',
    company_role: '',
    approved: '',
    confirmed: '',
    approved_by: '',
    company_url: '',
};

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public userInfo: UserInfo = defaultUserInfo;

    @action
    public async login(): Promise<UserInfo | undefined> {
        const accountService = new AccountService();
        const tokenService = new TokenStore();
        const response = await accountService.getInfoOnMe();

        tokenService.setMailInLocalStorage(response.email);

        if (response) {
            return response;
        }

        return undefined;
    }

    @action
    public async loginWithAccessToken(): Promise<boolean> {
        try {
            const accountService = new AccountService();
            const response = await accountService.getInfoOnMe();
            if (response) {
                this.setUserInfo(response);
            }

            return true;
        } catch (err: any) {
            try {
                const tokenStore = new TokenStore();
                const tokens = tokenStore.getTokens();

                if (!tokens?.refreshToken || !tokens?.mail) {
                    return false;
                }

                const accountService = new AccountService();
                const response = await accountService.getAccessTokenRefreshToken(
                    tokens.refreshToken,
                    tokens.mail
                );

                if (!response) {
                    return false;
                }

                tokenStore.setAccessTokenInLocalStorage(response.access_token);
                tokenStore.setRefreshTokenInLocalStorage(response.refresh_token);

                await this.login();
            } catch (err: any) {
                return false;
            }
        }

        return false;
    }

    @action
    public setUserInfo(userInfo: Partial<UserInfo>): void {
        this.userInfo = {
            ...this.userInfo,
            ...userInfo,
        };
    }

    @action
    public getUserInfo(): UserInfo {
        return this.userInfo;
    }

    @action
    public clearUserInfo(): void {
        this.userInfo = defaultUserInfo;
    }

    @action
    public isLogin(): boolean {
        return Boolean(localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN));
    }

    @action
    public isApproval(): boolean {
        return Boolean(this.userInfo.confirmed.length > 0);
    }
}

export default UserStore;
