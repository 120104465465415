import { SpeakerSlash, SpeakerHigh } from '@phosphor-icons/react';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useState, useRef, useEffect } from 'react';

import styles from './index.module.scss';

interface VideoPLayerProps {
    src: string;
}

export const VideoPlayer = ({ src }: VideoPLayerProps) => {
    const [muted, setMuted] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleMute = () => {
        if (!videoRef.current) return;
        videoRef.current.muted = !videoRef.current.muted;
        setMuted(videoRef.current.muted);
    };

    useEffect(() => {
        if (!videoRef.current) return;
        videoRef.current.play();
    }, [videoRef]);

    return (
        <div className={styles.videoContainer}>
            <video
                ref={videoRef}
                src={src}
                autoPlay
                loop
                controls={false}
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
            />
            <button className={styles.mute} onClick={handleMute} aria-labelledby="mute-video">
                <VisuallyHidden>{muted ? 'Un-mute Video' : 'Mute Video'}</VisuallyHidden>
                {muted ? (
                    <SpeakerSlash size={24} fill="#ffffff" weight="fill" />
                ) : (
                    <SpeakerHigh size={24} fill="#ffffff" weight="fill" />
                )}
            </button>
        </div>
    );
};
